.emoji-picker {
  font-size: 13px;
}

.emoji-picker .search-field {
  height: 24px;

  font-weight: 500;
  font-size: 10px;

  vertical-align: middle;
}

.emoji-picker .search-icon {
  position: absolute;
  top: 7px;
  left: 13px;
}

.emoji-picker .emoji-btn {
  font-size: 18px;
  width: 32px;
  height: 32px;
}

.emoji-picker .emoji-btn:hover {
  background-color: rgb(223, 223, 223);
  border-radius: 20%;
}

.emoji-picker .icons-container {
  height: 235px;
}

@media (max-width: 595px) {
  .emoji-picker .emoji-btn {
    font-size: 18px;
  }

  .emoji-picker .search-field {
    height: 30px;

    font-weight: 500;
    font-size: 13px;

    vertical-align: middle;
  }

  .emoji-picker .search-icon {
    position: absolute;
    top: 7px;
    left: 13px;
  }
}
