.instacap-boundingbox {
  display: flex;
  position: relative;
  /*box-shadow: 0 0 0 1000000px rgba(0, 0, 0, 0.40);*/
  background-color: transparent;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
  /*border: 4px solid #ff38a36e;*/
  box-sizing: border-box;
}
