.custom-loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: green;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin-custom-loader 1s linear infinite;
}

@keyframes spin-custom-loader {
  to {
    transform: rotate(360deg);
  }
}
