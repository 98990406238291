/* Center the loader */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px; /* Full height */
  background-color: #FFFFFF; /* Light background color */
}

/* Loader animation */
.simple-loader {
  width: 50px;
  height: 50px;
  border: 4px solid #ccc; /* Light grey border */
  border-top: 4px solid #3498db; /* Blue top border */
  border-radius: 50%; /* Make it circular */
  animation: spin 1s linear infinite; /* Infinite spinning animation */
}

/* Keyframes for spinning */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
