.tool-elem:hover .tool-remove-icon {
  display: block;
}

.tool-remove-icon {
  display: none;
}

.tool-loading > div {
  background-color: transparent !important;
}

.blur-tool {
  backdrop-filter: blur(10px) !important;
  background: rgba(255, 255, 255, 0.4);
}

.z-index-cursor-layer {
  z-index: 35;
}

.tool-elem:hover,
.tool-elem.active {
  border: 1px solid #70a3f3;
}

.tool-elem.arrow {
  border: none;
}

.tool-elem:hover .font-size-text {
  visibility: visible;
}

.font-size-text > span {
  position: relative;
  right: 3px;
}

.result-font-size {
  letter-spacing: 1px;
  text-shadow: -1px -1px 0 #fff, 0 -1px 0 #fff, 1px -1px 0 #fff, 1px 0 0 #fff,
    1px 1px 0 #fff, 0 1px 0 #fff, -1px 1px 0 #fff, -1px 0 0 #fff;
}
