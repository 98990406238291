img.chrome-extension-instacap-marker {
  cursor: move;
  user-select: none;
  display: flex;
  /* position: absolute; */
  max-width: none !important;
  max-height: none !important;
  filter: none !important;
  margin-bottom: 10px !important;
  height: auto;
}
r
textarea.instacap-textaea.cursor-text {
  cursor: text !important;
}

textarea.instacap-textarea,
input.instacap-textarea {
  font-family: Rubik, sans-serif !important;
  font-style: normal;
  line-height: 24px;
  font-weight: 700 !important;
  /* identical to box height */

  display: flex;
  align-items: center;

  border: none !important;
  outline: none !important;
  max-width: none !important;
  resize: none;

  /* position: absolute; */
  text-shadow: -2px -2px 0 #fff, 0 -2px 0 #fff, 2px -2px 0 #fff, 2px 0 0 #fff, 2px 2px 0 #fff, 0 2px 0 #fff,
    -2px 2px 0 #fff, -2px 0 0 #fff !important;
  box-shadow: none !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-radius: 10px !important;
  word-break: break-word;

  padding: 0 5px;
  margin: 5px 7px;

  overflow: hidden;
}

.instacap-textarea::selection {
  background-color: rgba(176, 255, 98, 0.4);
  color: rgb(107, 107, 107);
}

/*.instacap-textarea:focus {*/
/*  border-bottom: 2.5px solid !important;*/
/*  border-radius: 0px !important;*/
/*}*/

.instacap-textarea::placeholder {
  text-shadow: -1px -1px 0 #fff, 0 -1px 0 #fff, 1px -1px 0 #fff, 1px 0 0 #fff, 1px 1px 0 #fff, 0 1px 0 #fff,
    -1px 1px 0 #fff, -1px 0 0 #fff !important;
  opacity: 0.7 !important;
  color: inherit;
  font-family: Rubik, sans-serif !important;
}
