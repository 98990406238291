.teamsBtn {
    padding-top: 4.2px;
    :global .cl-rootBox.cl-organizationSwitcher-root {
        .cl-avatarBox.cl-organizationPreviewAvatarBox {
            border-radius: 2rem;
        }
        .cl-organizationSwitcherTrigger {
            .cl-avatarBox.cl-userPreviewAvatarBox {
                border-radius: 2rem;
            }
        }
        .cl-organizationSwitcherTrigger:focus {
            box-shadow: none;
        }
    }
}

.userProfile {
    :global .cl-avatarBox.cl-userButtonAvatarBox {
        height: 43px;
        width: 43px;
        border: 1px solid rgb(0, 0, 0);
        box-shadow: rgb(0, 0, 0) 1px 1px 0px;
    }
}

:global .cl-profilePage.cl-profilePage__account {
    .cl-profileSection.cl-profileSection__emailAddresses {
        .cl-profileSectionContent.cl-profileSectionContent__emailAddresses {
            div:first-of-type {
                .cl-accordionTriggerButton {
                    pointer-events: none;
                }
            }
            svg:first-of-type {
                display: none;
            }
            button.cl-profileSectionPrimaryButton.cl-profileSectionPrimaryButton__emailAddresses {
                display: none;
            }
        }
    }
    .cl-profileSection.cl-profileSection__connectedAccounts {
        display: none;
    }
}
:global .cl-profilePage.cl-profilePage__security {
    .cl-profileSection.cl-profileSection__activeDevices {
        display: none;
    }
}

