@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap');

@import 'tool/tool.css';
@import 'tool/boundingbox.css';
@import 'tool/toolBar.css';
@import 'tool/toolText.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: -webkit-fill-available;
  overflow: hidden;
}

.page-center-content {
  background-position: center center;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  background-color: white;
  background-image: linear-gradient(#ebebeb 1px, transparent 1px),
    linear-gradient(to right, #ebebeb 1px, white 1px);
  background-size: 26px 26px;
  overflow-x: hidden;
}
.custom-margin-top {
  margin-top: 16px;
}
.header {
  background: #ffffff;
  box-shadow: 0px 1px 0px #000000;
}
.btn-green {
  @apply flex items-center justify-center gap-2 text-black text-xxs tracking-widest bg-green hover:bg-green-tinted font-bold rounded-lg flex-initial min-w-min h-9 pt-px px-6 whitespace-nowrap;
}
.btn-gray {
  @apply flex items-center justify-center gap-2 text-black text-xxs tracking-widest bg-gray-200 hover:bg-green-tinted font-bold rounded-lg flex-initial min-w-min h-9 pt-px px-6 whitespace-nowrap;
}
.btn-outline {
  @apply flex items-center justify-center gap-2 text-black text-xxs tracking-widest bg-white hover:bg-gray-100 font-bold rounded-lg border-solid border-2 border-black flex-initial min-w-min h-9 pt-px px-6 whitespace-nowrap;
}
.btn-green-outline {
  @apply flex items-center justify-center gap-2 text-black text-xxxs tracking-widest bg-green hover:bg-green-tinted font-bold rounded-lg border-solid border-2 border-black flex-initial min-w-min h-9 px-6 whitespace-nowrap;
}
.btn-black {
  @apply flex items-center justify-center gap-2 text-white text-xxs tracking-widest bg-black hover:bg-gray-800 font-medium rounded-lg border-solid border-2 border-black flex-initial min-w-min h-9 pt-px px-6 whitespace-nowrap;
}
.header1-bold {
  @apply font-sans text-xl font-bold leading-normal truncate;
}
.header1 {
  @apply font-poppins font-medium text-xl	not-italic w-full truncate;
}
.subtext-grey-sm {
  @apply text-sm w-full text-gray-400 truncate sm:w-10/12;
}
.subtext-grey-xs {
  @apply font-sans text-xs leading-normal text-gray-400;
}
.subtext-grey-xxs {
  @apply font-sans text-xxs leading-normal text-gray-400;
}
.badge-grey {
  @apply inline-flex items-center justify-center ml-2 px-2 py-1 text-xs font-bold leading-none text-white bg-gray-400 rounded-full h-4;
}
.badge-grey-bigger {
  @apply inline-flex items-center justify-center ml-2 px-2 py-2 text-xs leading-none text-white bg-gray-400 rounded-full h-4;
}
.badge-comment {
  @apply border-solid border-1 border-white text-xxs text-white px-2 pt-px flex justify-center items-center w-5 h-5 rounded-tl-2xl rounded-tr-2xl rounded-br-2xl rounded-bl-md bg-gray-400;
}
.badge-comment-outline {
  @apply text-xs text-black border-solid border-2.5 border-black px-2 flex justify-center items-center w-6 h-6 rounded-tl-2xl rounded-tr-2xl rounded-br-2xl rounded-bl-md;
}
.tooltip {
  @apply invisible absolute flex items-center justify-center rounded-md shadow-lg pt-0.5 pb-0.5 pl-2 pr-2 leading-tight bg-black bg-opacity-70 text-white text-xxs tracking-wider;
}
.tooltip-notuppercase {
  @apply invisible absolute flex items-center justify-center rounded-md shadow-lg pt-0.5 pb-0.5 pl-2 pr-2 bg-black bg-opacity-70 text-white text-xxs;
}
.has-tooltip:hover .tooltip,
.has-tooltip:hover .tooltip-notuppercase {
  @apply visible z-50 delay-200;
}
.filepond--wrapper,
.filepond--browser,
.filepond--drop-label,
.filepond--panel,
.filepond--root,
.filepond--hopper,
.filepond--panel-root {
  @apply w-full bg-transparent;
}

.filepond--panel-root {
  @apply bg-white cursor-pointer !important;
}
.filepond--drop-label {
  @apply h-full rounded-lg text-black cursor-pointer !important;
}
.filepond--credits {
  @apply hidden;
}

.filepond--root .filepond--drop-label {
  transform: translate3d(0px, 0px, 0px) !important;
  z-index: 10 !important;
  height: unset;
}
@media (max-width: 1380px) {
  .full-wdith {
    width: 33%;
  }
  .margin-bottom {
    margin-bottom: 12px;
  }
  .user-updated-time {
    display: none;
  }
  .mobile-nav {
    justify-content: space-between;
  }
}

.upload-drag-txt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  color: #000000;
  margin-top: 12px;
}
.pink-txt {
  color: #ff38a4;
}
.filepond--list-scroller {
  @apply h-44 !important;
}

.block-upload * div,
input,
span {
  background-color: transparent !important;
}

.custom-upload-padding {
  padding-left: 134px;
  padding-right: 134px;
}
.upload-grey-txt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  color: #808080;
  margin-top: 8px;
}
.fileupload-dropdown .filepond--drop-label {
  background: white !important;
  color: black !important;
  border-width: 4px;
  margin-top: -12px;
  border: 2px dashed #e6e6e6;
  height: 216px !important;
  border-radius: 16px !important;
}
.fileupload-modal-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: #000000;
}
.dashboard-fileupload-dropdown .filepond--drop-label {
  background: white !important;
  color: black !important;
  border-width: 4px;
  border: 2px dashed #e6e6e6;
  height: 320px !important;
  border-radius: 0.75rem !important;
}
.update-border {
  border: #a4e365 !important;
  cursor: alias;
}
.update-border-reverse {
  border: 1px solid blue !important;
}
.live-url {
  z-index: 0;
}
.mobile {
  display: none;
}

.bottom-send {
  position: absolute;
  bottom: 17px;
  left: 82.75%;
}

@media (max-width: 595px) {
  .header-title {
    display: none;
  }

  .emoji-picker {
    font-size: 15px !important;
  }
}
@media (max-width: 420px) {
  .header-title {
    display: none;
  }
  .custom-upload-padding {
    padding-left: 0px;
    padding-right: 0px;
  }
  .mobile {
    display: block !important;
  }
}

.comment-number {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #808080;
  text-align: left;
}
.right-card-outer {
  background: #f7f5ee;
  border: 1px solid #000000;
  box-shadow: 1px 3px 0px #000000;
  border-radius: 16px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top: 0px;
}
.right-card {
  background: #f7f5ee;
  height: auto;
  max-height: 68vh;
  overflow: scroll;
  /* max-height: 607px; */
  /* overflow-y: scroll; */
  margin-right: -4px;
  padding-right: 2px;
  padding-left: 2px;
}
.right-inner-card {
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
}
#myTabContent p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
.name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}
.comment-time {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  align-items: center;
  color: #808080;
  line-height: 18px;
}

.custom-radio {
  background: #ffffff;
  border: 1px solid #000000;
  box-shadow: 1px 1px 0px #000000;
  border-radius: 100px;
}
.custom-radio-body {
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 100px;
}
.bg-default-card {
  background-color: #f7f5ee;
  margin-bottom: -1px;
}
.right-card::-webkit-scrollbar {
  width: 5px;
  padding: 12px;
}

.right-card::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 18px;
}

.bottom-card {
  background: #f7f5ee;
  border-top: 1px solid #000000;
  border-left: 1px solid #000000;
  border-bottom: 1px solid #000000;
  border-radius: 0px 0px 12px 12px;
  overflow: hidden;
  border-bottom: 7px solid;
  margin-right: -9px;
  position: relative;
  top: 0px;
  left: -1px;
}
.bottom-card-dashboard {
  background: #f7f5ee;
  border: 1px solid #000000;
  box-shadow: 1px 3px 0px #000000;
  border-radius: 0px 0px 12px 12px;
  overflow: hidden;
  border-bottom: 7px solid;
}
.tabs-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 37px;
  color: #000000;
  text-transform: none;
  width: 100%;
}

.tabs-heading-not-active {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 37px;
  color: #000000;
  text-transform: none;
  text-shadow: none;
}
.tabs-heading h5,
.tabs-heading-not-active h5 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 37px;
  align-items: center;
  color: #000000;
  position: relative;
  left: -10px;
  top: -10px;
}
.send-button {
  background: #abff57;
  border: 1px solid #000000;
  box-shadow: 1px 1px 0px #000000;
  border-radius: 100px;
  padding: 6px 10px;
  width: 40px;
  height: 40px;
  /* position: absolute;
  left: 267px;
  top: 11px; */
}
.record-button {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  /* box-shadow: 1px 1px 0px #000000; */
}
.email-input {
  background-color: white !important;
  border: 1px solid black;
  border-radius: 26px;
  height: 40px;
}
#sendButton2 {
  left: 202px;
}
.switch-txt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
  align-items: center;
  color: #000000;
}
.bg-custom-yellow {
  background-color: #f7f5ee;
}
.reply-input {
  border: 1px solid #ff38a4;
}
.replies {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  align-items: center;
  color: #000000;
}
.reply-time {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 9.7px;
  align-items: center;
  color: #808080;
}
.comments-img {
  width: 188px;
  position: absolute;
  z-index: 2;
  top: 2px;
  left: -2px;
}
.annotation-not-active {
  width: 188px;
  position: absolute;
  z-index: 2;
  top: 2px;
  left: -2px;
}

.default-tab {
  background-image: url('assets/images/default-tab.png');
  background-size: auto;
  background-position: 0px 4px;
  background-repeat: no-repeat;
  width: 341px;
  height: 63px;
}
.annotationTxt {
  left: -40px !important;
}
.annotaion-bg {
  background-image: url('assets/images/annotatio-tab.png');
  background-size: auto;
  background-position: 0px 4px;
  background-repeat: no-repeat;
  width: 341px;
  height: 50px;
}

.text-grey-100 {
  color: #808080;
}
.comment {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #2e2e2e;
}
.comment-emoji {
  font-size: 16px;
}
.min-arrow {
  margin: 0 auto;
}
.min-comment-bar {
  background: #f7f5ee;
  border: 1px solid #000000;
  box-shadow: 1px 3px 0px #000000;
  border-radius: 16px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 15px;
  max-height: 850px;
  height: auto;
}
.user-mini {
  position: absolute;
  top: 20%;
  left: 20%;
  border: 1px solid #000000;
}
@media (width: 912px) {
  .surface-pro-responsive {
    margin-top: -60px;
    margin-bottom: 53px;
  }
  #capture_board {
    max-height: unset;
  }
  .custom-height {
    height: 200px;
  }
}

@media screen and (max-width: 1090px) {
  .full-wdith {
    width: 40% !important;
  }
  .margin-bottom {
    margin-bottom: 12px;
  }
  .mid-width {
    width: 30% !important;
  }
  .user-updated-time {
    display: none;
  }
  .mob-slider {
    width: 42%;
  }

  .header .w-1\/3 {
    width: 101%;
  }
  .mobile-nav {
    flex-wrap: nowrap;
  }
}
@media (max-width: 1160px) {
  .title-div {
    display: none;
  }
  .full-wdith {
    width: 70% !important;
  }
  .mid-width {
    width: 30%;
  }
}

/* **** New Design **** */
.menuNew {
  position: absolute;
  inset: auto auto 0px 0px;
  margin: 0px;
  transform: translate(1212px, 834px);
}
.custom-p-26 {
  padding-top: 26px;
  padding-bottom: 26px;
}
.border-100 {
  border-radius: 100px;
}
.custom-green-btn {
  height: 44px;

  box-shadow: 1px 1px 0px #000000;
  border: 1px solid #000000;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0px !important;
  color: #000000;

  padding-top: 12px;
  padding-bottom: 12px;

  margin: 0 16px;
}

.custom-btn {
  height: 44px;

  box-shadow: 1px 1px 0px #000000;
  border: 1px solid #000000;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0px !important;
  color: #000000;

  padding-top: 12px;
  padding-bottom: 12px;
}

.row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.width-33 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.black-border {
  border: 1.5px solid #000000;
}
.users-count {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  align-items: center;
  text-align: center;
  color: #000000;
}
.counter-value {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
}
.views-txt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #ff38a4;
}

.list-tool {
  height: 44px;

  border: 1px solid rgba(0, 0, 0, 0.85);

  opacity: 1;
  transition: opacity 0.15s ease-in-out;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.list-tool > li {
  display: inline-block;
}

.tool-bar {
  outline: none !important;
}

.user-updated-time {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  align-items: center;
  color: #000000;
  opacity: 0.5;
}

.dropdown-upgrade {
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  padding-left: 0px;
  padding-right: 0px;
  position: relative;
  width: 112px;
  left: 98px;
  top: 10px;
}

.dropdown-upgrade button:hover {
  background-color: #abff57;
  color: black;
  border-radius: 0px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}
.dropdown-upgrade button {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  padding: 8px 20px 8px 20px;
}

.custom-black-radio {
  background-color: #000000;
}
.custom-green-radio {
  background-color: #abff57;
}

.custom-red-radio {
  background-color: #ff0000;
}
.input-reply-field {
  background-color: white !important;
  border-radius: 24px;
  font-family: 'Poppins';
}
.border-green-custom {
  border: 2px solid #abff57;
}
.tooltip-box {
  /* top: 27px !important;
  left: -26px !important; */
  border: black;
  color: white;
  font-size: 10px;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 400;
  z-index: 99;

  text-align: left;
}
.google-signin-rounded {
  position: absolute;
  top: 123%;
  left: 22%;
  border: black;
  color: white;
  font-size: 10px;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 400;
  z-index: 99;
}
.tooltip-annotation {
  position: absolute;
  top: 102%;
  left: -3%;
  border: black;
  color: white;
  font-size: 10px;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 400;
  z-index: 99;
}
.tooltip-signout {
  position: absolute;
  top: 131%;
  left: -3%;
  border: black;
  color: white;
  font-size: 10px;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 400;
  z-index: 99;
}
.margin-min-comment-bar {
  margin-bottom: 12px;
}

.user-avatar-custom {
  border: 1px solid #000000;
}
.translate-14px {
  --tw-translate-x: 14px;
}
.three-dots {
  left: 2px;
  top: 2px;
}
/* .intercom-lightweight-app-launcher {
  bottom: 5px !important;
  right: 104px !important;
} */
.changelog {
  bottom: 13px !important;
  left: 275px;
}
.intercom-namespace .intercom-1hjjtwt {
  bottom: 9px !important;
  left: 215px !important;
}
.textAreaCustom {
  margin-top: 7px;
  min-height: 39px;
  height: 40px !important;
}

#canny-changelog-iframe {
  left: 0.5% !important;
}
.empty-annotation-txt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}

.empty-comment {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.DeleteModal {
  width: 456px;
  background: #ffffff;
  border: 1px solid #000000;
  box-shadow: 1px 3px 0px #000000;
  border-radius: 16px;
}
.DeleteModal h4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: #000000;
}
.DeleteModal p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  opacity: 0.5;
}
.header {
  /* margin-bottom: 23px; */
  box-shadow: 0px 1px 0px #000000;
}
.header-dropdown {
  width: 241px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  z-index: 999;
  top: 29px;
  left: -180px;
  /* height: 224px; */
}
.pink-border-capture {
  border: 2px solid #ff38a4 !important;
  border-radius: 6px;
}
.dropdown-item {
  padding-top: 5.5px;
  padding-bottom: 5.5px;
}
.text-danger {
  color: #ff0000;
  font-weight: 500;
}
.header-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  color: #000000;
  border: 1px solid white;
}
.header-title:hover {
  border: 1px solid #dbdcdb;
  border-radius: 5px;
}
.creator-tooltip {
  background: white;
  color: black;
  padding-top: 21px;
  color: #808080;
  text-align: center;
}
.cancel-modal-btn,
.cancel-modal-btn:hover {
  width: 156px;
  height: 44px;
  background: #f2f2f2;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  align-items: center;
  text-align: center;
  color: #000000;
  border: 1px solid #000000;
  box-shadow: 1px 1px 0px #000000;
  border-radius: 100px;
}

.delete-modal-btn {
  width: 156px;
  height: 44px;
  background: #ff0000;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  align-items: center;
  text-align: center;
  color: white;
  border: 1px solid #000000;
  box-shadow: 1px 1px 0px #000000;
  border-radius: 100px;
  margin-left: 16px;
}

.unsaved-changes-modal-btn {
  width: 156px;
  height: 44px;
  background: #abff57;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  align-items: center;
  text-align: center;
  color: #000000;
  border: 1px solid #000000;
  box-shadow: 1px 1px 0px #000000;
  border-radius: 100px;
  margin-left: 16px;
}

.custom-modal-bg {
  background: #000000;
  opacity: 0.3 !important;
}
.title-field {
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  color: #000000;
}

.sidebar-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  align-items: center;
  color: #000000;
}
.sidebar-title-count {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  align-items: center;
  color: #808080;
}
.option-img {
  width: 44px;
  height: 44px;
}
.annotation-img {
  width: 44px;
  height: 44px;
}

.z-index-cursor-layer {
  z-index: 35;
}

.tooltip-left-tooltip-text {
  display: block;
  padding: 1px 3px;
  position: absolute;
  top: 35px;
  right: -5px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.7) !important;
  transform: translateY(50%) scaleY(0);
  transition: transform 0.3s ease-in-out;
  border-radius: 3px;
}

.wrapper-icon {
  position: relative;
}

.list-tool-item {
  border-radius: 42px;
  transition: all 0.25s ease-in-out;
}

.list-tool-item:hover .tooltip-left-tooltip-text {
  transform: translateX(0) scaleX(1);
}

.list-tool-item:hover {
  box-shadow: 0 0 1px 1px inset;
  padding-top: 9px;
  padding-bottom: 9px;
}

.list-tool-item:last-child {
  margin-bottom: 0;
}

.tool-loading > div {
  background-color: transparent !important;
}

.tool-elem:hover .remove-icon {
  display: block;
}

.remove-icon {
  display: none;
}

.blur-tool {
  backdrop-filter: blur(64px) !important;
}

img.chrome-extension-instacap-marker {
  cursor: move;
  user-select: none;
  display: flex;
  max-width: none !important;
  max-height: none !important;
  filter: none !important;
  margin-bottom: 10px !important;
  height: auto;
}
textarea.chrome-extension-instacap-textarea.cursor-text {
  cursor: text !important;
}
textarea.chrome-extension-instacap-textarea {
  margin: 18px !important;
}
textarea.instacap-textarea,
input.chrome-extension-instacap-textarea {
  font-family: 'Times New Roman', Times, serif !important;
  font-style: normal;
  line-height: 24px;
  font-weight: 700 !important;
  /* identical to box height */

  display: flex;
  align-items: center;
  border: none !important;
  outline: none !important;
  resize: none;
  max-width: none !important;

  text-shadow: -2px -2px 0 #fff, 0 -2px 0 #fff, 2px -2px 0 #fff, 2px 0 0 #fff,
    2px 2px 0 #fff, 0 2px 0 #fff, -2px 2px 0 #fff, -2px 0 0 #fff !important;
  box-shadow: none !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-radius: 10px !important;
  word-break: break-word;
}

.chrome-extension-instacap-textarea::selection {
  background-color: rgba(176, 255, 98, 0.4);
  color: rgb(107, 107, 107);
}
.chrome-extension-instacap-textarea:focus {
  border-bottom: 2.5px solid !important;
  border-radius: 0px !important;
}
textarea.instacap-textarea::placeholder {
  text-shadow: -1px -1px 0 #fff, 0 -1px 0 #fff, 1px -1px 0 #fff, 1px 0 0 #fff,
    1px 1px 0 #fff, 0 1px 0 #fff, -1px 1px 0 #fff, -1px 0 0 #fff !important;
  opacity: 0.7 !important;
  color: #000000;
  font-family: 'Times New Roman', Times, serif !important;
}

.chrome-extension-resizer-square {
  position: absolute;
  width: 9px;
  height: 9px;
  background: white;
  border: 1px solid #70a3f3;
  border-radius: 1px;
}
.chrome-extension-resizer-resizable-handler {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;

  z-index: 1;
}
.chrome-extension-resizer-resizable-handler.chrome-extension-resizer-tl,
.chrome-extension-resizer-resizable-handler.chrome-extension-resizer-t,
.chrome-extension-resizer-resizable-handler.chrome-extension-resizer-tr {
  top: -9px;
}
.chrome-extension-resizer-resizable-handler.chrome-extension-resizer-tl,
.chrome-extension-resizer-resizable-handler.chrome-extension-resizer-l,
.chrome-extension-resizer-resizable-handler.chrome-extension-resizer-bl {
  left: -9px;
}
.chrome-extension-resizer-resizable-handler.chrome-extension-resizer-bl,
.chrome-extension-resizer-resizable-handler.chrome-extension-resizer-b,
.chrome-extension-resizer-resizable-handler.chrome-extension-resizer-br {
  bottom: -9px;
}
.chrome-extension-resizer-resizable-handler.chrome-extension-resizer-br,
.chrome-extension-resizer-resizable-handler.chrome-extension-resizer-r,
.chrome-extension-resizer-resizable-handler.chrome-extension-resizer-tr {
  right: -9px;
}
.chrome-extension-resizer-resizable-handler.chrome-extension-resizer-l,
.chrome-extension-resizer-resizable-handler.chrome-extension-resizer-r {
  margin-top: -9px;
}
.chrome-extension-resizer-resizable-handler.chrome-extension-resizer-t,
.chrome-extension-resizer-resizable-handler.chrome-extension-resizer-b {
  margin-left: -9px;
}

.chrome-extension-resizer-t,
.chrome-extension-resizer-tl,
.chrome-extension-resizer-tr {
  top: -6px;
}
.chrome-extension-resizer-b,
.chrome-extension-resizer-bl,
.chrome-extension-resizer-br {
  bottom: -6px;
}
.chrome-extension-resizer-r,
.chrome-extension-resizer-tr,
.chrome-extension-resizer-br {
  right: -6px;
}
.chrome-extension-resizer-tl,
.chrome-extension-resizer-l,
.chrome-extension-resizer-bl {
  left: -6px;
}
.chrome-extension-resizer-l.chrome-extension-resizer-resizable-handler,
.chrome-extension-resizer-r.chrome-extension-resizer-resizable-handler,
.chrome-extension-resizer-l.chrome-extension-resizer-square,
.chrome-extension-resizer-r.chrome-extension-resizer-square {
  top: 50%;
  margin-top: -6px;
}
.chrome-extension-resizer-t.chrome-extension-resizer-resizable-handler,
.chrome-extension-resizer-b.chrome-extension-resizer-resizable-handler,
.chrome-extension-resizer-t.chrome-extension-resizer-square,
.chrome-extension-resizer-b.chrome-extension-resizer-square {
  left: 50%;
  margin-left: -6px;
}
.chrome-extension-resizer-tl.chrome-extension-resizer-square,
.chrome-extension-resizer-tr.chrome-extension-resizer-square,
.chrome-extension-resizer-bl.chrome-extension-resizer-square,
.chrome-extension-resizer-br.chrome-extension-resizer-square,
.chrome-extension-resizer-tl.chrome-extension-resizer-resizable-handler,
.chrome-extension-resizer-tr.chrome-extension-resizer-resizable-handler,
.chrome-extension-resizer-bl.chrome-extension-resizer-resizable-handler,
.chrome-extension-resizer-br.chrome-extension-resizer-resizable-handler {
  margin: 0px;
}

.tool-tip-txt {
  background: #000000a8 !important;
  /* margin-top: 20px; */
  padding: 10px;
}
.has-tooltip:hover .tooltip,
.has-tooltip:hover .tooltip-notuppercase {
  background: #000000a8 !important;
  /* padding-top: 0px; */
  color: white;
}
.tooltip-edit {
  top: 100% !important;
  left: 30% !important;
}
.user-email {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #808080;
}
.sidebar {
  box-shadow: 0px 0px 0px 1px #000000;
}
.custom-pl-51 {
  padding-left: 51px;
}
.sidebar-img {
  border: 1px solid #000000;
  border-radius: 6px;
}
.sidebar-comment-count {
  top: 66%;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  width: 22px;
  height: 16px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  text-align: center;
  color: #000000;
}
#textInput {
  border-radius: 17px;
  font-family: 'Poppins';
}

#textInput::-webkit-scrollbar {
  width: 5px;
  padding: 12px;
}

#textInput::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 18px;
}
.collapse-ava {
  margin-top: -1px;
}
.avatar-lg {
  width: 44px;
  height: 44px;
}
.img-main {
  height: 413px;
  width: 100%;
}
.sidebar-upload {
  background: #ffffff;
  border: 2px dashed #9f9f9f !important;
  border-radius: 6px;
  width: 100% !important;
}
.custom-grey {
  color: #808080;
}
.bg-custom-green {
  background: #abff57;
}
.sidebar {
  min-width: 318px;
  width: 318px;
}
.thumbnail-capture {
  width: 72px;
  height: 52px;
  margin-right: 8px;
  margin-left: 8px;
  border: 1px solid #000000;
}
.thumbnail-upload {
  width: 72px;
  height: 52px;
  /* margin-right: 16px; */
  border: 2px dashed #9f9f9f !important;
  background-color: white;
}
.w-fit {
  width: fit-content;
}

.thumbnail-capture-black {
  border: 1px solid #000000;
  border-radius: 6px;
}
.upload-btn-default {
  background-color: #f2f2f2 !important;
}
.sidebar-collapse {
  width: 108px;
  box-shadow: 0px 0px 0px 1px #000000;
}
.comments-sidebar-section {
  margin-top: 7px;
}
/* .capture-list {
  width: 338px;
} */

@media (max-width: 860px) {
  .sidebar-open {
    display: none;
  }
  .mob-slider {
    width: unset;
  }
  .tool-bar {
    display: none;
  }
  .capture-list {
    margin-bottom: 20px;
    padding-bottom: 15px;
    margin-top: 10px !important;
    overflow: scroll;
  }
  .intercom-lightweight-app-launcher {
    display: none;
  }
}
.gsignin-button {
  justify-content: center;
  align-items: center;
  padding: 12px 22px;
  width: 270px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #000000;
  box-shadow: 1px 1px 0px #000000;
  border-radius: 100px;
}
.privacy-description {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  align-items: center;
  text-align: center;
  color: #808080;
}
.pink-link {
  color: #ff38a4;
}
.not-allowed-comments {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  align-items: center;
  text-align: center;
  color: #000000;
  padding-top: 5%;
}
.anonymous-user {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 5%;
}
.logged-in-user {
  padding-left: 16px;
  padding-right: 16px;
}

.input-reply-field::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 18px;
}
.input-radius {
  border-radius: 9px !important;
}
.input-reply-field {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  align-items: center;
  color: #000000;
}
.upload-file-txt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #808080;
  padding-left: 8px;
}
.sidebar-dropdown {
  width: 167px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}
.w-90-per {
  width: 90%;
}
.three-dot-sidebar:hover,
.three-dot-sidebar-hover {
  background: #e5e7eb;
  border-radius: 100%;
}

.sidebar-capture-img {
  width: 60px;
  height: 44px;
}
.sidebar-dot {
  position: relative;
  bottom: 3px;
}

.download-capture {
  max-height: 100%;
}
/* .no-download-capture {
  max-height: 609px;
} */

#capture_board {
  height: fit-content;
  background-color: transparent;
}
#capture_board .capture-image-container {
  border: 2px solid #000000;
  border-radius: 16px;
  filter: drop-shadow(2px 4px 0px #000000);

  overflow: hidden;
  z-index: 10;
}
.input-reply-field::-webkit-scrollbar {
  width: 5px;
  padding: 12px;
}

@media (max-width: 980px) {
  .full-wdith {
    width: 70% !important;
  }
  .margin-bottom {
    margin-bottom: 12px;
  }
  .mid-width {
    width: 30% !important;
  }
  .user-updated-time {
    display: none;
  }
  .mobile {
    display: block !important;
  }
}
.sidebar-card {
  padding-top: 14px;
  padding-bottom: 8px;
}
.border-red-span-input {
  border: 1px solid #ff38a4 !important;
  border-radius: 5px !important;
}
.comments-sidebar {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #808080;
}
.sidebar-comment-time {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #808080;
}
.custom-padding {
  padding-left: 21px;
  padding-right: 21px;
}
.email-input:focus {
  border: 1px solid #ff38a4;
}
.border-red {
  border: 1px solid #ff0000 !important;
}
.name-error {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #ff0000;
}
.disabled-dropDown-button {
  background: #eaeaea;
  border-radius: 0px;
}
.custom-range {
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 100px;
  height: 6px;
  background: linear-gradient(
    90deg,
    rgb(26, 188, 156) 8%,
    rgb(215, 220, 223) 8.1%
  );
}
.main-user-dots {
  top: 3px;
  left: 21px;
}
.update-txt {
  position: relative;
}
.update-border {
  border: 2px dashed #ff38a4 !important;
  cursor: alias;
}
.update-border-reverse {
  border: 1px solid blue !important;
}
.live-url {
  z-index: 0;
}
@media (max-width: 420px) {
  .custom-upload-padding {
    padding-left: 0px;
    padding-right: 0px;
  }
  .upload-btn {
    font-size: 12px !important;
  }
}

@media (width: 912px) {
  .surface-pro-responsive {
    margin-top: -60px;
    margin-bottom: 53px;
  }
}
.default-modal {
  background: #ffffff;
  border: 1px solid #000000;
  box-shadow: 1px 3px 0px #000000;
  border-radius: 16px;
}
.upload-url-txt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* Main/First */

  color: #000000;
}
.upload-btn {
  height: 39px;
  background: #f2f2f2;
  border: 1px solid #000000;
  box-shadow: 1px 1px 0px #000000;
  border-radius: 100px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  align-items: center;
  text-align: center;
  color: #808080;
  position: absolute;
  right: 0;
  bottom: 0;
}
.upload-txtBox {
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 100px;
  align-items: center;
  padding-right: 115px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  padding-left: 16px;
  height: 40px;
}
.upload-error {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  align-items: center;

  color: #ff0000;
}
.upload-success {
  background: #abff57;
  border: 1px solid #000000;
  box-shadow: 1px 1px 0px #000000;
  border-radius: 100px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  align-items: center;
  text-align: center;
  color: #000000;
}
.border-danger {
  border: 1px solid #ff0000 !important;
}
.error-upload-file {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 150% !important;
  color: #ff0000;
}
.spinner-processing {
  border-color: 1px solid grey;
}
.share-modal {
  background: #ffffff;
  border: 1px solid #000000;
  box-shadow: 1px 3px 0px #000000;
  border-radius: 16px;
  width: 755px;
}
.profile-modal {
  background: #ffffff;
  border: none;
  box-shadow: 1px 3px 0px #000000;
  border-radius: 16px;
  width: 555px;
}
.New-modal-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #000000;
}
.share-input {
  background: #ffffff;
  border: 1px solid #808080;
  border-radius: 100px;
  padding: 12px 16px;
  width: 100%;
  height: 40px;
  padding-right: 117px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  align-items: center;
  color: #000000;
}
.share-modal-txtarea:focus-visible,
.share-modal-txtarea:focus,
.share-input:focus-visible,
.share-input:focus {
  border: 1px solid black;
  outline: none;
}
.send-txt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 106%;
  align-items: center;
  color: #000000;
}
.share-option {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  align-items: center;
  color: #000000;
}

.share-btn {
  width: 122px;
  height: 48px;
  background: #abff57;
  border: 1px solid #000000;
  box-shadow: 1px 1px 0px #000000;
  border-radius: 100px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 113%;
  color: #000000;
  padding: 12px 24px;
  position: relative;
  @apply hover:bg-green-tinted;
}
.share-disabled {
  width: 122px;
  height: 48px;
  background: #e6e6e6;
  border: 1px solid #000000;
  box-shadow: 1px 1px 0px #000000;
  border-radius: 100px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 113%;
  color: #000000;
  padding: 12px 24px;
  position: relative;
}
.share-modal-name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 209%;
  color: #000000;
}
.share-modal-options {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 209%;
  color: #000000;
}
.share-modal-name-invite {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 209%;
  color: #808080;
}
.public-access {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #000000;
}
.public-access-desc {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #808080;
}
.width-33 {
  width: 33.3%;
}
.share-copy-btn {
  padding: 10px 0px;
  width: 188px;
  height: 48px;
  border: 1px solid #000000;
  box-shadow: 1px 1px 0px #000000;
  border-radius: 100px;
  position: relative;
  font-family: 'Poppins';
  @apply hover:bg-gray-100;
}
.share-copy-btn-txt {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
}
.share-content {
  margin-left: 24px;
  margin-right: 12px;
  max-height: 361px;
  overflow-y: auto;
}

.share-modal-txtarea::-webkit-scrollbar {
  width: 5px;
  padding: 12px;
}

.share-modal-txtarea::-webkit-scrollbar-thumb {
  background: #e6e6e6;
  border-radius: 18px;
}

.share-content::-webkit-scrollbar {
  width: 5px;
  padding: 12px;
}

.share-content::-webkit-scrollbar-thumb {
  background: #e6e6e6;
  border-radius: 18px;
}

.inset-y-2-custom {
  top: 10px;
  bottom: 9px;
}
.content-option {
  position: relative;
  top: 8px;
  right: 4px;
}
.share-modal-txtarea {
  width: 698px;
  height: 104px;
  border: 1px solid #808080;
  border-radius: 16px;
  padding: 12px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}
.email-view-dropdown {
  padding: 8px 0px;
  width: 211px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  font-family: 'Poppins';
}

.email-view-dropdown-items {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  padding-top: 5.5px;
  padding-bottom: 5.5px;
}
.email-view-dropdown-items:hover {
  border-radius: 0px;
}
.copy-link-dropdown {
  width: 183px;
  top: -63px;
  left: 10px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}
.share-input-dropdown {
  width: 189px;
  height: 111px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}
@media (max-width: 780px) {
  .share-input,
  .share-input:focus-visible,
  .share-input:focus {
    width: 100%;
  }
  .share-modal-txtarea {
    width: 100%;
  }
  .share-copy-btn-txt {
    font-size: 12px;
  }
  .share-btn {
    width: 65%;
    height: 48px;
    background: #abff57;
    border: 1px solid #000000;
    box-shadow: 1px 1px 0px #000000;
    border-radius: 100px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 113%;
    color: #000000;
    padding: 0px;
  }
  .share-disabled {
    height: 48px;
  }
  .share-copy-btn {
    width: 100%;
  }
  /* .share-copy-btn-txt {
    display: none;
  } */
  .bottom-share {
    display: none;
  }
  .share-copy-btn {
    padding: 0px;
  }
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #000000;
}
.container-upload {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #000000;
}
.url-upload {
  display: block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #000000;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  background: #abff57;
  margin-left: 20px;
  border: 1px solid #000000;
  border-radius: 6px;
  background-color: #eee !important;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #abff57 !important;
}

/* .container input:checked ~ .checkmark-bg-grey {
  background-color: red !important;
} */

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

/* ************ */

.radio-custom .container {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #000000;
}

/* Hide the browser's default radio button */
.radio-custom .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio-custom .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 13.33px;
  width: 13.33px;
  background: #abff57;
  border: 1px solid #000000;
  border-radius: 50%;
  margin-left: 20px;
}

/* On mouse-over, add a grey background color */
.radio-custom .container:hover input ~ .radio-custom .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-custom .container input:checked ~ .radio-custom .checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-custom .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-custom .container input:checked ~ .radio-custom .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-custom .container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 5.33px;
  height: 5.33px;
  background: #000000 !important;
  border: 1.33333px solid #000000;
  border-radius: 50%;
}

.custom-tooltip-box {
  top: 116px !important;
  left: 20px !important;
  border: black;
  color: white;
  font-size: 10px;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 400;
  z-index: 99;
}
.bg-grey-shareInput:hover {
  background: #cdc8c8;
}

.chrome-extension-instacap-boundingbox {
  display: flex;
  position: relative;
  box-shadow: 0 0 0 1000000px rgba(0, 0, 0, 0.45);
  background-color: transparent;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
  border: 2px solid #ff38a36e;
  box-sizing: border-box;
  border-radius: 8px;
}
.sidebar-notification-collapse {
  left: 4.5rem;
  display: none;
}
.sidebar-notification-open {
  left: 4.7rem !important;
  top: 1rem;
  display: none;
}
.slider-notification {
  position: absolute;
  left: 82%;
  z-index: 99999;
  top: -5px;
  display: none;
}

.avatar-dropdown {
  width: 154px;
  background: #ffffff;
  border: 1px solid #000000;
  left: 10%;
}
.avatar-dashboard-dropdown {
  width: 118px;
  background: #ffffff;

  /* right: 4%; */
}
.avatar-inner-dropdown {
  padding-top: 5.5px;
  padding-bottom: 5.5px;
}
.usecases-dashboard-dropdown {
  width: 250px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  z-index: 9999;
  /* right: 4%; */
}
.avatar-inner-dropdown:hover {
  background-color: #a4e365;
}
.logout-txt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  align-items: center;
  color: #000000;
  padding-left: 8px;
}
.user-bg-hover {
  border-radius: 38px;
  background: white;
}
.user-bg-hover:hover {
  border-radius: 38px;
  background: #e6e6e6;
}
.sidebar-title-input {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  border: 1px solid #ff38a4;
  line-height: 150%;
}
.sidebar-title-input:hover {
  border: 1px solid #dbdcdb;
  border-radius: 5px;
}
[contenteditable] {
  outline: 0px solid transparent;
}
.input-dropDown-ToolTipe {
  top: 118px !important;
  left: 45px !important;
  border: black;
  color: white;
  font-size: 10px;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 400;
  z-index: 99;
}

.toaster-notification > div > div:nth-child(2) > div::after {
  border-color: #000;
}

.toaster-notification > div > div {
  border: 2px solid #000;
  border-radius: 50%;
}
.toaster-notification > div:nth-child(2) {
  margin: 4px 5px 4px 10px !important;
  flex: none !important;
}
.sidebar-Capture-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  cursor: auto;
}
.sidebar-Capture-title-count {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #808080;
}
.sidbar-dropdown-capture {
  width: 270px;
  background: #ffffff;
  font-family: 'Poppins';
  font-style: normal;
  font-size: 14px;
  color: #000000;
}
.transparent-border {
  border: 1px solid transparent !important;
}
.bottom-card-Text {
  background: #abff57;
  border: 1px solid #000000;
  box-shadow: 1px 3px 0px #000000;
  border-radius: 0px 0px 12px 12px;
  overflow: hidden;
  border-bottom: 7px solid;
  margin-right: -9px;
  position: relative;
  top: 10px;
  left: -1px;
}

.emoji-container {
  position: absolute;
  top: 0;
  right: initial;
  left: initial;
  bottom: initial;

  margin-left: initial;
  margin-right: initial;
  margin-top: initial;

  z-index: 1000;
}

.emoji-picker {
  width: 278px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  background-color: white !important;
  border: 1px solid black;
  border-radius: 8px;
  padding: 13px 16px 14px 16px;
  z-index: 1000;

  -webkit-box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.3);
}

.emoji-picker .icons-container::-webkit-scrollbar {
  width: 5px;
  padding: 12px;
}

.emoji-picker .icons-container::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 18px;
}

.emoji-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-family: 'Poppins';
  font-size: 14px;

  padding: 6px 8px;
  margin-right: 4px;
  margin-bottom: 4px;

  border: 0.83px solid #e6e6e6;
  border-radius: 8px;

  cursor: pointer;
}

.emoji-badge .count {
  font-size: 12px;
}

.emoji-badge-comment {
  margin-top: 12px;
}

.emoji-badge-reply {
  margin-top: 0;
  margin-bottom: 12px;
}

.emoji-badge-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: absolute;
  left: 0;
  top: 20px;

  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;

  padding: 14px 20px;

  border: 1px solid #000000;
  border-radius: 8px;

  -webkit-box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.3);

  background: #ffffff;

  z-index: 1000;
}

.comment-text-count {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #808080;
  margin-bottom: 5%;
}

.scrollbox {
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(
        50% 100%,
        farthest-side,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    radial-gradient(
      farthest-side at 50% 0,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}

.help-icon-mini {
  position: absolute;
  top: 20%;
  left: 20%;
}

.tooltip-box-commentMessage {
  /* top: 27px !important; */
  /* left: -26px !important; */
  border: black;
  color: white;
  font-size: 10px;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 400;
  z-index: 99;

  right: 4.5%;
  /* top: 35%; */
}
.comment-text-count-toolTip {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #808080;
}

.text-distance {
  margin-top: 6rem;
  margin-bottom: 1.5rem;
}
.sider-bar-text {
  font-family: 'Poppins';
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

/* .grid-capture-img {
  width: 70%;
  height: 5rem;
  margin: auto;
} */
@media (max-width: 860px) {
  /* * {
    border-color: transparent;
  } */
  .intercom-lightweight-app-launcher {
    display: none;
  }
  .changelog {
    display: none;
  }

  .sidebar {
    box-shadow: none;
    height: 100%;
  }

  .mobile-nav {
    padding: 16px 0px;
  }

  .header-dropdown {
    top: 80% !important;
    left: 60% !important;
  }
  .mob-slider {
    position: absolute;
    bottom: 0;
    /* top: 0; */
    left: 25%;
    right: 25%;
    /* /* transform: translate(35%);  */
  }

  /* Sidebar */
  .w-15-sm {
    width: 15%;
  }

  .share-input,
  .share-input:focus-visible,
  .share-input:focus {
    width: 100%;
  }
  .h-92-sm {
    display: flex;
    flex-direction: column;
    height: 92%;
  }
  .bottom-share {
    display: block;
  }
  .share-copy-btn-txt {
    font-size: 16px;
  }
  /* .copy-link-dropdown {
    transform: translateX(50%);
  } */
  .right-card {
    max-height: 77.7vh;
  }
  .bottom-card {
    border: none;
    position: absolute !important;
    bottom: 0;
    top: unset;
    left: 0;
    right: 0;
    margin-right: 0;
    border-top: 1px solid #000;
    border-radius: unset;
  }
  .r-tex-grey {
    color: #808080;
  }
  .r-tab-active {
    color: #000 !important;
    background-color: #ffffff !important;
    /* border-width: 1px; */
    border-color: #000;
    opacity: 1;
    transition: all ease-in-out 300ms;
  }

  .copy_capture {
    position: fixed;
    margin: 0px !important;
    top: 50% !important;
    bottom: unset !important;
    left: 50% !important;
    right: unset;
    height: 100%;
    transform: translate(-50%, -50%);
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  .copy_capture ul {
    background: #fff;
    max-width: 207px;
    border-radius: 11px;
    margin-inline: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 4px 9px 18px 5px rgb(0 0 0 / 50%);
  }

  .mob-slider {
    padding: 0px;
    position: fixed;
    /* inset: 0 0 0; */
    top: unset;
    bottom: unset;
    right: unset;
    left: unset;
    height: max-content;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: center;
  }
  .height-733 {
    height: 400px;
  }
  #capture_board {
    max-height: unset;
    /* width: 1000px; */
    overflow: unset !important;
  }
  .captcha-capture {
    max-width: unset !important;
  }
  .mrpx-0 {
    padding: 0 !important;
  }

  .capture-container {
    height: 65vh;
  }
  .image-container {
    padding: 0px 10px;
  }
  .share-modal-name {
    white-space: nowrap;
    overflow: hidden;
    width: 90%;
    text-overflow: ellipsis;
  }
  .mobile-logo-sidebar {
    position: absolute;
    right: 16px;
    bottom: 18px;
  }
  .toaster-notification {
    font-size: 13px !important;
  }
  .custom-w {
    margin-left: 0px;
  }
  .share-input-container {
    flex: 1;
  }
  .modal-btn-set {
    all: unset;
    flex: 0 0 40px;
    max-width: 40px;
    margin-left: 10px;
  }
  .share-btn {
    width: 100%;
  }
  .share-disabled {
    width: 100%;
  }
  .email-input-dropdown {
    right: -30px;
  }
  .plr-6 {
    padding-left: 6% !important;
    padding-right: 6% !important;
  }
  .capture_board img {
    border-radius: 10px;
  }
  .emoji-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    left: 0;
    right: 0;
    bottom: 20px;
    top: initial;
  }
  .emoji-picker {
    width: 100% !important;
    border-radius: unset !important;
    border: none !important;
  }
  .top-logo a img {
    width: 170px;
  }
  .capture-list {
    overflow: unset !important;
  }
  .cancel-modal-btn,
  .delete-modal-btn {
    width: 45% !important;
    height: 40px !important;
  }

  .DeleteModal.default-modal {
    padding-left: 2.1rem;
    padding-right: 2.1rem;
  }

  .DeleteModal h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
  }
}

/* .cursor-not-allowed {
  background-color: #f2f2f2 !important;
} */

.toaster-notification {
  font-family: poppins;
  font-size: 16px;
  max-width: initial;
  padding: 20px;
  box-shadow: rgb(0 0 0 / 30%) 0px 12px 30px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  font-weight: 600;
  border-radius: 8px;
}

@media (max-width: 390px) {
  .w-15-sm {
    width: 27%;
  }
}
@media (max-width: 380px) {
  .share-modal-open {
    all: unset;
    box-shadow: 1px 1px 0px #000000;
    border: 1px solid #000000;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    letter-spacing: 0px !important;
    padding: 10px;
    border-radius: 50%;
    background-color: #abff57;
  }
}

.comment-Arrow-position {
  position: absolute;
  right: 0.8rem;
  top: 0.5rem;
}
.avatar-dropdown-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
}
.active-page {
  color: white !important;
  background-color: #ff38a4 !important;
  border-radius: 50px;
  padding-left: 8px;
  padding-right: 8px;
}
.parent-pagination li {
  color: grey;
  background-color: transparent;
  border-radius: 50px;
  width: 45px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-around;
}
.next {
  color: black !important;
  font-size: 30px;
}
.previous {
  color: black !important;
  font-size: 30px;
}
.container-width {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}
.dashboard-inner-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #808080;
  margin-bottom: 5%;
}

@media only screen and (max-width: 1240px) and (min-width: 1024px) {
  .coment-text {
    font-size: 12px;
  }
}

.rename-btn {
  width: 22%;
  height: 39px;
  background: #abff57;
  border: 1px solid #000000;
  box-shadow: 1px 1px 0px #000000;
  border-radius: 100px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  align-items: center;
  text-align: center;
  color: #000000;
  position: absolute;
  top: -18%;
  left: 78%;
}

.container input:checked ~ .checked-grey {
  background-color: #e6e6e6 !important;
}

.comment-modal {
  background: #f7f5ee;
  border: 1px solid #000000;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  box-shadow: 1px 3px 0px #000000;
}

.loom-recrding-duration {
  font-size: 14px;
}

.video-uploaded {
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 14px;
}
.desktop-header {
  flex: 0 0 40px;
  max-width: 40px;
}
.desktop-share-dots {
  flex: 0 0 40px;
  max-width: 40px;
}

.capture-index-count {
  z-index: 2;
  top: -10%;
  left: 5%;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  width: 36px;
  height: 22px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  text-align: center;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}
.capture-index-count span {
  font-size: 12px;
  font-weight: 400;
}

@media screen and (orientation: landscape) and (max-width: 991px) {
  .landscape-land {
    /* overflow: scroll !important; */
  }
}

.form-label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px !important;
  color: #808080;
  margin-top: 8px;
  margin-bottom: 5px;
  line-height: 15px;
}

.radio-button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.setting-content {
  height: 625px;
}
.profile-save-btn {
  position: absolute;
  bottom: 4%;
  right: 4%;
}
.social-text {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media only screen and (max-width: 860px) {
  .setting-modal {
    height: 90vh !important;
    /* display: none; */
    width: 90%;
  }
  .settings-sidebar {
    display: none;
  }

  .input-row {
    flex-direction: column;
    margin-right: 2rem;
  }
  .input-block {
    margin-top: 0px;
  }
  .profile-input {
    width: 90% !important;
    /* margin-right: 8px; */
  }
  .profile-save-btn {
    position: unset;
    width: 95%;
    margin-top: 35px;
  }
  .profile-save-box {
    margin-top: 2.8rem;
    height: 1.5rem;
  }
}
.default-Camera {
  background-image: url('assets/images/camera.png');
  /* background-image: url('assets/icons/cameraSvg.svg'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
}
.green-Camera {
  background-image: url('assets/images/cameraGreen.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
}
.bubble-1-border {
  border-width: 1.5px 1.5px 3px 1.5px;
  font-size: 10px;
}
.bubble-2-border {
  border-width: 1.5px 3px 1.5px 1.5px;
  font-size: 10px;
}
.bubble-3-border {
  border-width: 1.5px 1.5px 3px 1.5px;
  font-size: 10px;
}
.bubble-4-border {
  border-width: 1.5px 1.5px 3px 1.5px;
  font-size: 10px;
}
.buddle-text {
  width: 100%;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  margin-left: 8px;
  margin-top: 2px;
  font-size: 10px;
}
#green-Camera::before {
  content: '';
  position: absolute;
  transform: rotate(-30deg);
}
.zoom-container-wrapper {
  width: 100% !important;
  height: 100% !important;
  max-height: calc(100% - 96px);
}
.zoom-container-content {
}
.zoom-container-wrapper.active,
.zoom-container-content.active {
  cursor: grabbing;
}
.capture-image-container img {
  pointer-events: auto !important;
}
.editor-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.blackImportant {
  background: black !important;
  border: 2px solid black !important;
  color: white !important;
}

.editor-main {
  position: relative;
  z-index: 0;
  background-color: #1f2937;
  background-color: transparent;
  flex-grow: 1; /* Fill remaining space */
}

.background-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 0;
  flex-basis: 33.3333%;
  /* margin-top: 65px; */
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow-y: auto;
}
.left-sidebar {
  width: 380px;
  max-width: 380px;
  min-width: 380px;
}
.sidebar-section-editor {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 0;
  flex-basis: 33.3333%;
  margin-top: 65px;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 30;
}

/* Media queries for responsiveness */
@media screen and (max-width: 1250px) {
  .editor-container {
    flex-direction: column;
  }

  .background-section,
  .sidebar-section-editor {
    flex-basis: auto; /* Reset flex-basis to let content determine width */
    position: static; /* Reset position */
    height: auto; /* Reset height */
  }
  .left-sidebar {
    width: 100%;
    max-width: 100%;
  }
}
