.tool-bar {
  outline: none !important;
}

.list-tool {
  height: 44px;
  border: 1px solid rgba(0, 0, 0, 0.85);

  opacity: 1;
  transition: opacity 0.15s ease-in-out;
}

.list-tool > li {
  display: inline-block;
}

.list-tool.is-hidden {
  transform: translateX(-60%) scale(1, 0);
  opacity: 0;
}

.list-tool-item {
  border-radius: 42px;
  transition: all 0.25s ease-in-out;
}

.list-tool-item:hover .tooltip-left-tooltip-text {
  transform: translateX(0) scaleX(1);
}

.list-tool-item:hover {
  box-shadow: 0 0 1px 1px inset;
}

.tool-wrapper-icon {
  position: relative;
}

.list-tool-item:last-child {
  margin-bottom: 0;
}

.tooltip-left-tooltip-text {
  display: inline-block;
  position: absolute;
  top: 2px;
  right: 35px;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: 400;
  text-transform: capitalize;
  vertical-align: middle;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.7) !important;
  transform: translateX(50%) scaleX(0);
  transition: transform 0.3s ease-in-out;
  border-radius: 5px;
}

@media (max-width: 860px) {
  .tool-bar {
    display: none;
  }
}
